import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  const { site, file } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      file(relativePath: { eq: "wellspring-logo.png" }) {
        childImageSharp {
          fixed(height: 125 ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <header className="bg-gray-200">
      <div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
        <Link to="/">
          <h1 className="flex items-center text-gray-700 no-underline">
            <Img fixed={file.childImageSharp.fixed} alt="Wellspring Craniosacral Therapy logo" />

            <span className="text-xl font-bold tracking-tight hidden">
              {site.siteMetadata.title}
            </span>
          </h1>
        </Link>

        <div className="flex-initial w-64" />

        <Link to="/appointments" className="block bg-gray-600 hover:bg-gray-700 md:text-xl text-white font-bold tracking-wide md:tracking-wider py-2 px-4 rounded shadow">
          Book Now
        </Link>

        <button
          // className="flex items-center block px-3 py-2 text-gray-700 border border-gray-700 rounded md:hidden"
          className="flex items-center block px-3 py-2 text-gray-700 border border-gray-700 rounded"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          // className={`${
          //   isExpanded ? `block` : `hidden`
          // } md:block md:flex md:items-center w-full md:w-auto`}
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full`}
        >
          {[
            {
              route: `/about-bcst`,
              title: `What is BCST?`
            },
            {
              route: `/benefits-of-bcst`,
              title: `Benefits of BCST`
            },
            {
              route: `/womens-health-and-children`,
              title: `Women's Health & Children`
            },
            {
              route: `/animal-wellness`,
              title: `Animal Wellness`
            },
            // {
            //   route: `/about-angela`,
            //   title: `Bio`,
            // },
            {
              route: `/about-angela`,
              title: `About Angela`,
            },
            {
              route: `/appointments`,
              title: `Appointments`,
            },
            {
              route: `/testimonials`,
              title: `Testimonials`,
            },
            {
              route: `/events`,
              title: `Events`,
            }
          ].map((link) => (
            <Link
              // className="block mt-4 text-sm lg:text-base text-gray-700 no-underline md:inline-block md:mt-0 md:ml-6"
            className="block mt-4 text-sm lg:text-base text-gray-700 no-underline md:text-right"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
