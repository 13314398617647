import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import IgIcon from '../images/ig-icon.svg';

function Footer() {
  return (
    <footer className="bg-gray-500">
      <div
        className="max-w-6xl pt-4 px-4 mx-auto md:pt-8 md:px-8"
      >
        <OutboundLink
          href="https://www.instagram.com/wellspringcranio/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={IgIcon}
            alt="Wellspring on Instagram"
            width="32"
            className="inline-block"
          />
        </OutboundLink>

        <br />

        <Link
          to="/appointments"
          className="mt-3 inline-block bg-gray-600 hover:bg-gray-700 md:text-xl text-white font-bold tracking-wide md:tracking-wider py-2 px-4 rounded shadow"
        >
          Book an Appointment
        </Link>

        <p className="text-white pt-4 text-base">
          <Link
            to="/appointments"
            className="no-underline hover:underline"
          >
            Serving Central Alberta including Edmonton, Leduc, Wetaskiwin, Camrose, Ponoka, Red Deer, and surrounding areas.
          </Link>
        </p>

        <p className="text-white py-4 text-xs">
          <a
            href="mailto:wellspringcranio@gmail.com"
            className="no-underline hover:underline"
          >
            wellspringcranio@gmail.com
          </a>
          {" "}|{" "}
          <a
            href="tel:+17802162609"
            className="no-underline hover:underline"
          >
            780-216-2609
          </a>

          <br />

          Copyright &copy; 2022 Wellspring Craniosacral Therapy, all rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
